/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 54번째 로그에서는 AWS 공인 자격증, 파이썬 3.8 릴리스, 4분코딩 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.patreon.com/stdoutfm"
  }, "stdout.fm are creating 클라우드, 소프트웨어 개발, 전자 제품에 대해 이야기하는 프로그래머들의 팟캐스트 | Patreon")), "\n"), "\n", React.createElement(_components.h3, null, "'앨런 튜링, 지능에 관하여' 출간"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=198322526"
  }, "알라딘: 앨런 튜링, 지능에 관하여")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=59715228"
  }, "알라딘: 컴퓨터 과학이 여는 세계")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=HTWSPoDLmHI&list=PL0Nf1KJu6Ui7yoc9RQ2TiiYL9Z0MKoggH"
  }, "SNUON_컴퓨터과학이 여는 세계_1.1 과목소개_이광근 - YouTube")), "\n"), "\n", React.createElement(_components.h3, null, "삼성 S10 지문 문제"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.reuters.com/article/us-samsung-elec-smartphone/samsung-to-patch-galaxy-s10-fingerprint-problem-idUSKBN1WW0Q5"
  }, "Samsung says will soon patch Galaxy S10 fingerprint recognition problem - Reuters")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.newsis.com/view/?id=NISX20191018_0000803357"
  }, "아무 지문에나 열리는 '갤S10'…카드사, 일제히 주의 공지 :: 공감언론 뉴시스통신사 ::")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techcrunch.com/2019/08/10/tesla-model-3-owner-implants-rfid-chip-to-turn-her-arm-into-a-key/"
  }, "Tesla Model 3 owner implants RFID chip to turn her arm into a key | TechCrunch")), "\n"), "\n", React.createElement(_components.h3, null, "AWS 공인 자격증 취득 - 클라우드 전문가, 솔루션 아키텍트 어소시에이트"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/certification/?nav=tc&loc=3"
  }, "AWS Certification")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/certification/certified-cloud-practitioner/"
  }, "AWS 공인 클라우드 전문가")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/certification/certified-solutions-architect-associate/"
  }, "AWS 공인 솔루션스 아키텍트 – 어소시에이트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/certification/benefits/"
  }, "AWS Certification 혜택")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.udemy.com/course/aws-certified-cloud-practitioner-practice-test/"
  }, "AWS Certified Cloud Practitioner: 6 full practice tests 2019 | Udemy")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.udemy.com/course/aws-certified-solutions-architect-associate/"
  }, "Aws Certified Solutions Architect: Pass the Associate Exam | Udemy")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/iq/"
  }, "AWS IQ")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.cncf.io/certification/cka/"
  }, "Expert Certification - Cloud Native Computing Foundation")), "\n"), "\n", React.createElement(_components.h3, null, "파이썬 3.8 출시"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.python.org/ko/3.8/whatsnew/3.8.html"
  }, "What's New In Python 3.8 — Python 3.8.0 문서")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/python-3-8-release-note-summary"
  }, "파이썬(Python) 3.8 릴리스와 주요 변경 사항 - 할당표현식, 위치 한정 파라미터, f-문자열 평가식 지원 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.python.org/dev/peps/pep-0572/"
  }, "PEP 572 -- Assignment Expressions | Python.org")), "\n"), "\n", React.createElement(_components.h3, null, "VS Code의 파이썬 지원 강화"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://devblogs.microsoft.com/python/python-in-visual-studio-code-october-2019-release/"
  }, "Python in Visual Studio Code – October 2019 Release | Python")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/millejoh/emacs-ipython-notebook"
  }, "millejoh/emacs-ipython-notebook: Jupyter notebook client in Emacs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://colab.research.google.com/"
  }, "Google Colab")), "\n"), "\n", React.createElement(_components.h3, null, "4분 코딩 이야기"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/playlist?list=PLIUCBpK1dpsNbqvMRDip7XluyIpnwB1Af"
  }, "4분코딩 - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=3FcFL2C3ME8"
  }, "4분코딩 - 왜 도커(Docker)를 사용해야 할까요? - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=iNXTXefTsrs"
  }, "4분코딩 - 2019 최고의 에디터 비주얼 스튜디오 코드(Visual Studio Code) - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=jg2ha2RIWN0"
  }, "4분코딩 - 리액트(React)와 찰떡 궁합 Next.js - YouTube")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
